<template>
  <div>
    <!-- navigation  -->
    <div class="fixed top-0 left-0 w-full py-4 transition duration-200 ease-linear bg-transparent" :class="fixtop
        ? '!bg-white shadow-md z-10'
        : 'bg-transparent bg-gradient-to-b from-white lg:from '
      ">
      <div class="container">
        <div class="flex items-center justify-between w-full">
          <div class="w-1/2 lg:w-1/3">
            <img src="/images/main-logo-1.png" alt="" class="w-32" />
          </div>
          <div class="lg:hidden">
            <!-- <button
              @click="showMenu()"
              class="inline-block sidebar-toggle-btn"
            >
              <svg
                class="w-8 h-8 text-gray-600 fill-current"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M29 6H3a1 1 0 0 0 0 2h26a1 1 0 0 0 0-2ZM3 17h13a1 1 0 0 0 0-2H3a1 1 0 0 0 0 2Zm22 7H3a1 1 0 0 0 0 2h22a1 1 0 0 0 0-2Z"
                  data-name="menu"
                />
              </svg>
            </button> -->

            <!-- call button -->
            <div>
              <a href="tel:+60194191911">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 128 128"
                  class="w-10 h-10 mx-auto fill-current" :class="fixtop ? 'text-white' : 'text-red-1'">
                  <path
                    d="M50 1.5C39.1 3.9 27.6 10.3 19 19c-25.3 25.2-25.3 64.8 0 90 25.2 25.3 64.7 25.3 90 0 8.8-8.7 15.1-20.1 17.5-31.6 9.6-45.3-31-85.6-76.5-75.9zm-.7 36.4c3.7 3.8 6.7 7.2 6.7 7.5 0 .3-2.3 3.1-5 6.1-2.7 3-5 6.2-5 6.9C46 62.7 65.7 82 70.1 82c1.1 0 4.2-2 6.8-4.5 2.6-2.5 5.5-4.5 6.3-4.5.8 0 4.5 3.1 8.2 7 7.8 8 7.8 9-.2 14.1-3.4 2.2-5.8 2.9-9.4 2.9-8.8 0-21.7-6.7-32.1-16.8C34.1 65 26.9 48 32.5 39.3c2.8-4.5 6.7-8.3 8.5-8.3.9 0 4.7 3.1 8.3 6.9z" />
                </svg>
              </a>
            </div>
          </div>

          <div class="hidden lg:block">
            <div>
              <p class="w-48 py-2 font-semibold text-center text-white rounded-md bg-red-1">
                <a href="tel:+60194191911"> Call Us 019-419 1911</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fixtop: false,
      // showMobileMenu: false,
      // menu: [
      //   { a: "Our Services", link: "#our_services" },
      //   { a: "Why Choose Us", link: "" },
      //   { a: "About Us", link: "" },
      // ],
    };
  },
  methods: {
    scrollListener: function (e) {
      this.fixtop = window.scrollY > 150;
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>

<style>
.nav-menu {
  padding-top: 10px;
  position: absolute;
  width: 100%;
}

.open-menu {
  opacity: 1;
  height: 150px;
}

.closed-menu {
  opacity: 0;
  height: 0;
  padding: 0;
}
</style>
