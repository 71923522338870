<template>
  <div>
    <!-- header -->
    <WaBtn />
    <MainBanner />

    <!-- product -->
    <div class="py-12">
      <div class="container">
        <p class="text-2xl font-semibold text-center text-gray-700 md:text-3xl">
          Our Range of <span class="text-red-1">Products</span>
        </p>
        <div class="bg-red-1 w-12 mx-auto mt-3 h-0.5"></div>

        <div class="mt-6">
          <p class="text-lg font-medium text-center text-gray-900">
            Curtains Style
          </p>
          <p class="pt-3 text-sm leading-tight text-center text-gray-600">
            Every home need curtains because it enhances the ambiance, we
            customize products to your specific requirements so that your room
            can achieve the perfect decorative effect in addition, we will
            ensure that the result is suitable for your home design and style.
          </p>
          <div class="mt-4">
            <hooper :settings="hooperSettings" class="h-full">
              <slide v-for="(slide, indx) in slides" :key="indx" :index="indx" class="md:px-1">
                <div class="relative mx-auto">
                  <img :src="'/images/' + slide.image + '.jpg'" alt="" class="w-full" />
                  <div class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30">
                    <p class="pt-4 pl-4 text-lg font-semibold text-left text-white">
                      {{ slide.p }}
                    </p>
                  </div>
                </div>
              </slide>
              <hooper-navigation slot="hooper-addons"></hooper-navigation>
            </hooper>
          </div>
        </div>

        <div class="mt-8">
          <p class="text-lg font-medium text-center text-gray-900">
            Blinds Style
          </p>
          <p class="pt-3 text-sm leading-tight text-center text-gray-600">
            When the Windows are wide and long, shutters are the best choice.
            They are easy to operate and have a clean appearance. It is a
            minimalist interior design in the home or gives a superior
            appearance to the office.
          </p>
          <div class="mt-4">
            <hooper :settings="hooperSettings" class="h-full">
              <slide v-for="(slide, indx) in slides_2" :key="indx" :index="indx" class="md:px-1">
                <div class="relative mx-auto">
                  <img :src="'/images/' + slide.image + '.jpg'" alt="" class="w-full" />
                  <div class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30">
                    <p class="pt-4 pl-4 text-lg font-semibold text-left text-white">
                      {{ slide.p }}
                    </p>
                  </div>
                </div>
              </slide>
              <hooper-navigation slot="hooper-addons"></hooper-navigation>
            </hooper>
          </div>
        </div>
      </div>
    </div>

    <!--Hospital Curtain-->
    <div class="py-10">
      <div class="container">
        <div class="md:flex md:items-center xl:max-w-5xl xl:mx-auto">
          <div class="mb-4 md:w-1/2 md:pr-4">
            <h2 class="text-4xl font-bold text-gray-800 md:text-5xl">
              Hospital <span class="text-red-1">Curtain</span>
            </h2>
            <p class="pt-2 text-xl font-medium md:pt-4">Our Hospital Curtain are made to order and can be customized to
              fit your space.
              Perfect for medical facilities and other applications.</p>
          </div>
          <div class="shadow-md md:w-1/2 lg:w-1/3 lg:mx-auto">
            <img src="/images/banner-2.jpg" alt="" class="" />
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div id="why_choose_us" class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-choose.jpg')">
      <div class="container">
        <p class="text-2xl font-semibold text-center text-white md:text-3xl">
          Why Choose Us
        </p>
        <div class="bg-red-1 w-12 mx-auto mt-3 h-0.5"></div>

        <div class="mt-10 md:flex md:flex-wrap">
          <div v-for="(item, i) in choose" :key="i" class="w-2/3 pb-5 mx-auto md:w-1/2 lg:w-1/5">
            <img :src="'/images/' + item.image + '.png'" :alt="item.alt" class="w-20 mx-auto" />
            <div class="pt-4 leading-tight text-center text-white">
              <p>{{ item.alt }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- sub banner -->
    <div class="py-10">
      <div class="container">
        <div class="md:flex md:items-center xl:max-w-5xl xl:mx-auto">
          <div class="mb-4 md:w-1/2 md:pr-4">
            <!-- <p class="pb-2 text-2xl text-gray-600 lg:text-3xl">
              Looking for a decor expert? Contact us today!
            </p> -->
            <h2 class="text-4xl font-bold text-gray-800 md:text-5xl">
              Looking for a <span class="text-red-1">decor expert?</span>
            </h2>
            <p class="pt-2 text-xl font-medium md:pt-4">Contact us today!</p>

            <div class="pt-3">
              <p>Our Customisation Service comes with:</p>
              <ul class="pt-2 space-y-1 text-base lg:flex lg:flex-wrap">
                <li v-for="list in sublisting" :key="list" class="flex flex-wrap items-center lg:w-1/2">
                  <i class="pr-2 text-base fa-solid fa-circle-check"></i>
                  <p>{{ list }}</p>
                </li>
              </ul>
            </div>

            <div class="mt-6">
              <p class="w-64 py-1 text-center text-white bg-red-500 rounded-md">
                <a href="https://wa.me/60194191911">GET FREE QUOTE TODAY!</a>
              </p>
            </div>
          </div>
          <div class="shadow-md md:w-1/2 lg:w-1/3 lg:mx-auto">
            <img src="/images/banner-1.jpg" alt="" class="" />
          </div>
        </div>
      </div>
    </div>

    <!-- faq section -->
    <!-- <div class="py-10">
      <div class="container">
        <p
          class="text-2xl font-semibold text-center text-gray-700 capitalize md:text-3xl"
        >
          frequently asked <span class="text-red-1">questions</span>
        </p>
        <div class="bg-red-1 w-12 mx-auto mt-3 h-0.5"></div>
        <Accordion :faq="faq" :focus="false" class="pt-4 lg:max-w-3xl lg:mx-auto" />
      </div>
    </div> -->

    <!-- gallery -->
    <div class="py-10">
      <div class="container">
        <p class="text-2xl font-semibold text-center text-gray-700 capitalize md:text-3xl">
          Our <span class="text-red-1">Gallery</span>
        </p>
        <div class="bg-red-1 w-12 mx-auto mt-3 h-0.5"></div>

        <div class="flex flex-wrap mt-6">
          <div v-for="image in gallery" :key="image" class="w-1/2 p-1 lg:w-1/5 md:w-1/3">
            <img :src="'/images/' + image + '.jpg'" alt=""
              class="transition duration-300 transform md:w-full hover:scale-110 hover:shadow-md" />
          </div>
        </div>
      </div>
    </div>

    <!-- about us -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <div class="md:flex md:flex-wrap md:items-center">
          <div class="md:w-1/2 md:pr-4">
            <p class="text-lg font-medium text-gray-600 lg:text-2xl">
              About Us
            </p>
            <h1 class="text-4xl font-semibold text-gray-600">
              TSK <span class="text-red-1">Gallery</span>
            </h1>
            <div class="bg-blue-1 w-12 mt-3 h-0.5"></div>
            <p class="pt-6 text-sm leading-tight text-gray-600">
              TSK Gallery is a company that supplies beautiful hand finished
              curtains, pelmets, blinds, cushions, bed covers, etc. Our main
              office is located in Puchong, Selangor, Malaysia. <br /><br />
              We want to create a one-stop solution provider furnish stylish,
              fashionable curtain product, and to render best service to our
              client needed. Through passionate and persistent performance, we
              make customised and coordinated design curtain affordable to the
              urban minded customer.
            </p>
          </div>
          <div class="mt-4 md:w-1/2 lg:w-1/3 lg:mx-auto md:mt-0">
            <img src="/images/about-us.png" alt="" class="" />
          </div>
        </div>
      </div>
    </div>



    <Testimonials />

    <!-- footer -->
    <div id="element" class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')">
      <div class="container lg:flex lg:items-center">
        <div class="md:w-2/3 md:mx-auto lg:w-1/2 lg:order-2">
          <!-- enquiry form  -->
          <div class="p-6 rounded-md bg-red-1 bg-opacity-60">
            <div class="text-center">
              <p class="text-3xl font-semibold text-white">Send an Enquiry</p>
              <div class="bg-white w-14 h-0.5 mx-auto my-3"></div>
              <p class="pt-3 text-sm text-gray-100">
                Fill out the form below and we'll get back to you as soon as
                possible
              </p>
            </div>
            <EnquiryForm class="pt-4" />
          </div>
        </div>
        <div class="pt-5 lg:w-1/2 lg:pr-4">
          <img src="/images/main-logo-2.png" alt="" class="mx-auto rounded-md w-60" />
          <div class="pt-3 text-center text-white">
            <div class="pb-5">
              <h1 class="pb-2 text-lg font-medium lg:text-xl text-blue-1">
                TSK Curtain Gallery
              </h1>
              <h1 class="text-lg font-normal leading-tight md:text-xl">
                56-1, Jalan Puteri 2/2, Bandar Puteri, 47100 Puchong, Selangor.
              </h1>
              <div class="pt-5">
                <p class="font-bold">Message Us on WhatsApp</p>
                <p class="font-medium transition duration-300 transform hover:scale-110 hover:text-red-1">
                  <a href="https://wa.me/60194191911" class="">+6019-4191911</a>
                </p>
                <p class="font-medium transition duration-300 transform hover:scale-110 hover:text-red-1">
                  <a href="https://wa.me/60124394327" class="">+6012-4394327</a>
                </p>
              </div>

              <div class="pt-4 font-medium">
                <p class="font-bold">Call Us</p>
                <p class="font-medium transition duration-300 transform hover:scale-110 hover:text-red-1">
                  <a href="tel:+60194191911">+6019-4191911</a>
                </p>
                <p class="font-medium transition duration-300 transform hover:scale-110 hover:text-red-1">
                  <a href="tel:+60124394327">+6012-4394327</a>
                </p>
              </div>

              <div class="pt-4 font-medium">
                <p class="font-bold">Operating Hours</p>
                <p>Monday – Friday: 10AM – 5PM</p>
                <!-- <p>(Factory visit by appointment only)</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-3 bg-black">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainBanner from "@/components/MainBanner.vue"
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";
import Accordion from "@/components/Accordion.vue";
import Testimonials from "@/components/Testimonials.vue"
import EnquiryForm from "@/components/EnquiryForm.vue"
import WaBtn from "@/components/WaBtn.vue"

export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    Accordion,
    Testimonials,
    EnquiryForm,
    MainBanner,
    WaBtn
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        autoPlay: true,
        infiniteScroll: true,
        hoverPause: false,
        wheelControl: false,
        playSpeed: 4000,
        breakpoints: {
          767: {
            centerMode: false,
            itemsToShow: 2,
          },
          1000: {
            itemsToShow: 3,
            centerMode: false,
          },
        },
      },
      slides: [
        { image: "curtain-1", p: "Single Pleat" },
        { image: "curtain-2", p: "Ripple Fold" },
        { image: "curtain-3", p: "Double Pleat" },
        { image: "curtain-4", p: "French Pleat" },
        { image: "curtain-5", p: "Eyelet" },
      ],
      slides_2: [
        { image: "blind-1", p: "Roman Blinds" },
        { image: "blind-2", p: "Roller Blinds" },
        { image: "blind-3", p: "Zebra Blinds" },
        { image: "blind-4", p: "Wooden Blinds" },
      ],
      choose: [
        { image: "choose-1", alt: "More than 2,000+ Projects Completed" },
        { image: "choose-2", alt: "Premium Quality & Durability" },
        {
          image: "choose-3",
          alt: "98% of our customers love our designs and workmanship",
        },
        { image: "choose-4", alt: "Customised to Fit Your Needs" },
        { image: "choose-5", alt: "Reasonable Price" },
      ],
      sublisting: [
        "Free Consultation",
        "Free Quotation",
        "Free Home Visit",
        "Free Site Measurement",
      ],
      faq: [
        {
          q: "What should I have at the window?",
          a: "We pride ourselves on our consultative approach to all our projects and we can help with suggestions and give you direction so that you can then make the most informed decision. Firstly, you should consider the hanging system – pole, track, pelmet, or blinds (roller, Roman, or Venetian to name a few). Once this is settled, we will then be able to guide you as to the best next step based on the individual window and your requirements",
        },
        {
          q: "How do I measure up?",
          a: "Simply follow our measuring instructions. To make sure you get it right you will need your curtain pole or track in place first if you're measuring for curtains. Always use a metal tape measure.",
        },
        {
          q: "Can I iron my curtains?",
          a: "We recommend you simply hang them up straight away and the creases will disappear on average within 2 weeks. Ironing the fabric could be damaging and ruin your curtains.",
        },
        {
          q: "Can I put my fabrics in the washing machine?",
          a: "No, this is not advisable. The fabric will shrink, there’s a high possibility colours might run and the linings will get ruined. Curtains can be dry cleaned at your own risk but please note they can shrink by up to 3% during the process. If you have to take your blackout/thermal curtains to the dry cleaners please let them know that they'll need to be put through a very sensitive treatment.",
        },

        {
          q: "Are your blinds child safe?",
          a: "Absolutely. We provide chain tidy’s and cleats to make sure that our blinds are safe, and we’re also happy to send out free blind safety packs. Email us or give us a call on <a href='tel:+60194191911' style='font-weight: 700;'>019-4191911</a> to request one.",
        },
      ],
      gallery: [
        "gallery-1",
        "gallery-2",
        "gallery-3",
        "gallery-4",
        "gallery-5",
        "gallery-6",
        "gallery-7",
        "gallery-8",
        "gallery-9",
        "gallery-10",
      ],
    };
  },
};
</script>

<style>
.hooper-next,
.hooper-prev {
  background-color: rgba(255, 255, 255, 0.616);
}
</style>
